<template>
    <div class="site-main-wrapper" role="main">
        <div class="homepage-container promo-banner-container">
            <div class="page-background"></div>
            <section id="mainSection">
                <div class="container masthead-container">
                    <div class="masthead">
                        <div class="text-content">
                            <h1>Welcome To Goodyear Negotiations</h1>
                            <p>Thank you for your interest in Goodyear's labor contract negotiations. The current master
                                contract between Goodyear and the United Steelworkers (USW) is in effect until
                                 July 2026.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="promo-section-empty">
              <!-- <b-container>
                <b-row>
                  <b-col sm="12" md="6">
                    <div class="home-promo">
                      <span class="icon-warranty"></span>
                      <h2>Goodyear's Goal</h2>
                      <p>Our goal for negotiations is to reach a new contract that positions Goodyear and the USW for success in the future – including increased productivity at our USW plants. </p>
                      
                    </div>
                  </b-col>

                  <b-col sm="12" md="6">
                    <div class="home-promo">
                      <span class="icon-chat"></span>
                      <h2>Text & Email Nixle Alerts </h2>
                      <p>Check back regularly for new information about the negotiations process, or register to receive an alert by text or email when something new is posted. </p>
                      <p class="notice">Text GYNEWS to 333111 to sign up now</p>
                      
                    </div>
                  </b-col>
                </b-row>
              </b-container> -->
            </section>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
    };
  },
  computed: {},
  metaInfo () {
    return {
      title: 'Home | ' + this.$store.getters["getAppTitle"],
      meta: [
          { name: 'description', content: 'Check here for information and updates regarding the 2022 Goodyear-United Steelworkers master contract negotiations.' }
        ]
    } 
  }
};
</script>

<style scoped>
.page-background {
  background-image: url("../assets/images/background/single-tire-md.jpg");
}
.masthead {
  max-width: 630px;
  padding-top: 15px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 0px auto 95px;
  border-width: 10px;
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.93);
  border: 12px solid rgba(255, 255, 255, 0.6);
  padding-bottom: 15px;
  position: relative;
  border-radius: 10px;
}
.masthead .text-content h1 {
  text-transform: uppercase;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 10px;
  line-height: 110%;
  display: block;
  color: #274ba7;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 48px;
}
.promo-section {
  background-color: rgba(204,204,204, .96);
  background-image: url('../assets/images/diagonal.png');
  background-repeat: repeat;
  background-color: #999;
}
.promo-section .flex {
  padding: 15px;
}
.home-promo {
  background-color: #fff;
  text-align: left;
  padding: 15px 15px 5px 15px;
  min-height: 250px;
  border-radius: 10px;
}
.home-promo h2 {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 15px;
  padding-top: 5px;
}
.home-promo .btn {
  min-width: 150px;
  float: right;
}
.home-promo .notice {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}
.home-promo [class^="icon-"],
.home-promo [class*=" icon-"] {
  font-size: 90px;
  color: #274ba7;
  float: left;
  margin: 0 20px 10px 0;
}

#mainSection {
  padding-top: 20px;
}
.section-overview {
  font-size: 18px;
  margin: 30px 0;
  line-height: 1.5;
}
.masthead .text-content {
  text-align: center;
  margin: 0 auto;
}
.masthead .text-content h2 {
  font-size: 30px;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 10px;
  line-height: 110%;
  display: block;
  color: #6d6e71;
  -webkit-font-smoothing: subpixel-antialiased;
}
.masthead .text-content p {
  color: #333;
  margin: 0 0 20px;
  line-height: 135%;
}

.promo-section-empty {
  min-height: 350px;
}

@media (max-width: 445px) {
  .page-background {
    background-image: url("../assets/images/background/single-tire-xs.jpg");
  }
}
@media (max-width: 767px) {
  .home-promo {
    margin-bottom: 25px;
    min-height: 200px;
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .promo-section .container {
    padding: 50px 24px;
  }
}
@media (min-width: 1038px) {
  .home-promo .btn {
    margin-top: 30px;
  }
}
@media (min-width: 1200px)  {
  .home-promo {
    min-height: 250px;
  }
}
@media (min-width: 1281px) and (max-width: 1400px) {
  .page-background {
    background-image: url("../assets/images/background/single-tire-lg.jpg");
  }
}
@media (min-width: 1401px) and (max-width: 2000px) {
  .page-background {
    background-image: url("../assets/images/background/single-tire-xl.jpg");
  }
}
@media (min-width: 2001px) {
  .page-background {
    background-image: url("../assets/images/background/single-tire.jpg");
  }
}
</style>
