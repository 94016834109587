import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import GYNHome from '../views/GYNHome.vue'
/*import QuickFacts from '../views/QuickFacts.vue'
import Issues from '../views/Issues.vue'
import ContactUs from '../views/ContactUs.vue'
import News from '../views/News.vue'*/
import Error404 from '../views/404.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  { path: '/', name: 'gyn-home', component: GYNHome },
/*  { path: '/news-updates', name: 'gyn-news', component: News },
  { path: '/quick-facts', name: 'gyn-facts', component: QuickFacts },
  { path: '/key-issues', name: 'gyn-issues', component: Issues },
  { path: '/contact-us', name: 'gyn-contact', component: ContactUs },*/
  { path: '*', name: '404-error', component: Error404 }
]

export default new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 }
  }
})