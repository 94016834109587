<template>
    <div class="Footer">
        <footer>
            <div class="wrapper-inner">
                <b-container>
                    <b-row>
                        <!-- <b-col sm="12" md="4" class="footer-link-list">
                            <h3>Resources</h3>
                            <ul>
                                <li>
                                    <a class="footLink" href="/news-updates"> News Updates </a>
                                </li>
                                <li>
                                    <a class="footLink" href="/quick-facts"> Quick Facts </a>
                                </li>
                                <li>
                                    <a class="footLink" href="/key-issues"> Key Issues </a>
                                </li>
                                <li>
                                    <a class="footLink" href="/contact-us"> Contact Us </a>
                                </li>
                            </ul>
                        </b-col> -->

                        <b-col sm="12" md="8" class="footer-link-list">
                            <p class="small"> This site is intended to be purely informational. Goodyear will use it to
                                provide status updates and information about contract negotiations between the Company
                                and the United Steelworkers. This site is not intended as a means for negotiating with
                                the Union or dealing directly with bargaining unit members. Goodyear will update this
                                website as developments warrant, and visitors are encouraged to check the site
                                periodically for current information on the status of negotiations.
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="footer-end">
                        <b-col sm="12" md="7">
                          <div>&copy; {{ new Date().getFullYear() }} The Goodyear Tire & Rubber Company</div>
                        </b-col>
                        <b-col sm="12" md="5">
                            <a class="footLink"
                               href="https://corporate.goodyear.com/us/en/more/terms-conditions-and-privacy-policy.html"
                               target="_blank">Terms & Conditions and Privacy Policy</a>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  background-color: #274ba7;
  background: #274ba7;
  padding: 15px 15px 20px 15px;
  overflow: hidden;
  border-top: 2px solid #fd0;
  color: #fff;
  min-height: 300px;
}
.wrapper-inner {
  max-width: 1180px;
  margin: 0 auto;
  overflow: hidden;
}
h3 {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  color: #fff;
}
a, .v-btn {
  color: #fd0;
}
a:hover {
  text-decoration: underline;
  color: #fd0;
}
a {
  text-decoration: none;
}
ul {
  -webkit-padding-start: 0;
  padding-left: 0;
  list-style-type: none;
}
ul li {
  margin-top: 8px;
  margin-bottom: 8px;
}
.footer-end {
  margin-top: 15px;
  font-size: 16px;
  color: #fff;
}

@media (min-width: 768px) {
  .footer-link-list {
    margin-top: 12px;
    padding-left: 20px;
  }
  .footer-end {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 980px) {
  .footer-link-list {
      padding-left: 40px;
  }
}
</style>
