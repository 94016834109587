import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import authInterceptor from './interceptors/auth.interceptor'
import VueMeta from 'vue-meta'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/styles/style.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueMeta)

authInterceptor.initAuthInterceptor(store);

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  /^uib-accordion/
]

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')