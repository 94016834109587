<template>
    <div class="navbar-negot-custom">
        <div class="container-liquid-parent">
            <div class="Header container-liquid">
                <b-navbar class="all-sites-nav">
                    <b-container>
                        <b-navbar-nav class="ml-auto">
                            <b-nav-item right class="topNavLink" vuec-click @click="showModal" type="button"><span
                                      class="icon"><span
                                          class="icon-about"> </span><span class="other-gy">Other Goodyear Sites
                                    </span></span></b-nav-item>
                            <b-modal hide-footer size="lg" id="modal-1" ref="modal1" title="BootstrapVue">
                                <template #modal-header="{ close }">
                                    <a class="pull-right" vuec-click @click="close()"><i class="icon icon-x-mark"
                                           aria-hidden="true"></i></a>
                                    <div class="text-center"><img class="gylogo" alt="Goodyear logo"
                                             src="../assets/images/goodyear-title.png"></div>
                                </template>
                                <div class="allSitesModal modal-body allSitesModalLinks">
                                    <div class="row">
                                        <div class="col-xs-12 col-md-6">
                                            <h3 class="section-header-text">Specialty Sites</h3>
                                            <ul>
                                                <li><a href="https://www.goodyearaviation.com/" target="_blank">
                                                        <p>Aviation Tires</p><span class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                                <li><a href="https://www.goodyear.com/" target="_blank">
                                                        <p>Auto/Light Truck Tires</p><span
                                                              class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                                <li> <a href="https://www.goodyeartrucktires.com/" target="_blank">
                                                        <p>Commercial Truck Tires</p><span
                                                              class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                                <li> <a href="https://gov.goodyear.com/" target="_blank">
                                                        <p>Government Tire Sales </p><span
                                                              class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                                <li><a href="https://www.goodyearfleetnetwork.com" target="_blank">
                                                        <p>National Accounts</p><span
                                                              class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                                <li> <a href="https://www.goodyearotr.com/" target="_blank">
                                                        <p>Off-the-Road Tires</p><span
                                                              class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                                <li> <a href="https://www.racegoodyear.com/" target="_blank">
                                                        <p>Racing Tires</p><span class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                                <li> <a href="https://www.goodyearrvtires.com/" target="_blank">
                                                        <p>RV Tires</p><span class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                            </ul>
                                        </div>
                                        <div class="col-xs-12 col-md-6">
                                            <h3 class="section-header-text">Other Sites</h3>
                                            <ul>
                                                <li> <a href="https://corporate.goodyear.com/us/en.html"
                                                       target="_blank">
                                                        <p>Goodyear Corporate Site</p><span
                                                              class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                                <li> <a href="https://www.goodyearautoservice.com/" target="_blank">
                                                        <p>Goodyear Auto Service Centers</p><span
                                                              class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                                <li> <a href="https://www.justtires.com/" target="_blank">
                                                        <p>Just Tires</p><span class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                                <li> <a href="https://www.goodyearblimp.com/" target="_blank">
                                                        <p>Goodyear Blimp</p><span class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                                <li> <a href="https://www.thegoodyearstore.com/" target="_blank">
                                                        <p>Goodyear Merchandise</p><span
                                                              class="icon-thin-arrow-right"></span>
                                                    </a></li>
                                                <li> <a href="https://www.goodyearchemical.com/" target="_blank">
                                                        <p>Goodyear Chemical</p><span class="icon-thin-arrow-right">
                                                        </span>
                                                    </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </b-modal>
                        </b-navbar-nav>
                    </b-container>
                </b-navbar>
                <b-navbar toggleable="lg" class="navbar-sec">
                    <b-container id="gynav">
                        <a class="gohome" href="/"><img class="gy_logo"
                                 src="../assets/images/goodyear_negot_logo.png" alt="Goodyear Negotiations Logo"></a>
                        <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
                        <b-collapse id="nav-collapse" is-nav>
                            <!-- <b-navbar-nav class="main nav navbar-nav">
                                <b-nav-item class="topNavLink first"
                                            vuec-click
                                            href='/quick-facts'>Quick Facts
                                          </b-nav-item>
                                <b-nav-item class="topNavLink"
                                            vuec-click
                                            href='/key-issues'>Key Issues
                                </b-nav-item>
                                <b-nav-item class="topNavLink"
                                            vuec-click
                                            href='/news-updates'>News Updates
                                </b-nav-item>
                                <b-nav-item class="topNavLink"
                                            vuec-click
                                            href='/contact-us'>Contact Us
                                </b-nav-item>
                            </b-navbar-nav> -->
                        </b-collapse>
                    </b-container>
                </b-navbar>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
      isAddActiveClassToNav1: false,
      isAddActiveClassToNav2: false,
      isAddActiveClassToNav3: false,
      isAddActiveClassToNav4: false,
      isNavbarCollapsed: false,
    };
  },
  computed: {},
  methods: {
    showModal() {
      this.$refs["modal1"].show();
    },
    showModal2() {
      this.$refs["modal2"].show();
    },
    toggleNavbar() {
      if (this.isNavbarCollapsed) {
        this.isNavbarCollapsed = false;
      } else {
        this.isNavbarCollapsed = true;
      }
    },
  },
};
</script>

<style scoped>
.container-liquid-parent {
  width: 100%;
  position: relative;
}

.container-liquid {
  background-color: #274ba7;
  border-bottom: 2px solid #fd0;
}

.all-sites-nav.navbar {
  display: block;
  height: 33px;
  background-color: #0b286f;
  width: 100%;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
  padding: 0;
  margin: 0;
  line-height: 100%;
  overflow-y: hidden;
}

.all-sites-nav li,
.all-sites-nav a {
  color: #fd0;
}
.all-sites-nav li:hover,
.all-sites-nav li a:hover {
  text-decoration: underline;
}
.navbar {
  color: #fff;
  padding: 0rem 1rem;
}

.navbar-sec {
  position: relative;
  min-height: 50px;
  border: 1px solid transparent;
}
.navbar-nav .nav-item,
.navbar-nav .nav-item .nav-link {
  color: #fff;
  font-size: 16px;
  border: none;
  box-shadow: none;
}
.navbar-nav .nav-item:hover,
.navbar-nav .nav-item:hover .nav-link {
  color: #fd0;
}
.logo {
  height: 58px;
  width: auto;
  padding-left: 2%;
}
.ml-auto .nav-item,
.ml-auto .nav-item .nav-link {
  color: #fd0;
}
.navbar .navbar-toggler {
  background-color: rgba(255, 221, 0, 0.95);
  border-radius: 0px;
}
.navbar-nav li.active > a,
.navbar-nav .active > .nav-link,
.navbar-light .navbar-nav:focus {
  color: #fd0 !important;
}

.nav-link:focus {
  color: #fff !important;
}

@media (max-width: 767px) {
  .logo {
    padding: 5px 2px 5px 2%;
  }
}

@media (min-width: 768px) {
  .navbar-negot-custom {
    top: 0;
    border-width: 0 0 1px;
    right: 0;
    left: 0;
    z-index: 1030;
  }
}

@media (min-width: 768px) {
  .top-menu-container {
    max-width: 1180px;
    margin: 0 auto;
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media (max-width: 991px) {
  .navbar-nav .nav-item .nav-link {
    background-color: transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-nav .nav-item:hover .nav-link {
    color: #fd0;
    background-color: rgba(23, 60, 140, 0.5);
  }
}
@media (min-width: 992px) {
  .navbar-nav {
    margin-left: 15%;
  }
  .main.navbar-nav .nav-item.first .nav-link {
    border: none;
    box-shadow: none;
  }
  .main.navbar-nav .nav-item .nav-link {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    background-color: transparent;
    box-shadow: none;
    margin: 12px 0;
    padding: 3px 10px;
  }
}
</style>
