import axios from 'axios';
const jose = require('jose');

export default {
    initAuthInterceptor: (store) => {

        // Add a request interceptor
        axios.interceptors.request.use(
            async function (config) {
                let payload = { email: "" };

                const accessToken = await new jose.SignJWT(payload)
                    .setProtectedHeader({ alg: 'HS256' })
                    .setExpirationTime(store.getters["getJWTAccessTimeout"])
                    .sign(new TextEncoder().encode(
                        store.getters["getJWTAccessSecret"],
                    ));

                config.headers['x-custom-authorization'] = 'Bearer ' + accessToken;
                return config;
            }, function (error) {
                // Do something with request error
                return Promise.reject(error);
            });

    }
}