import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
const APP_TITLE = 'Goodyear Negotiations';
const RECAPTCHA_SECRET_FRONT = '6LcpcqkeAAAAALSRihgOQKR-z0QU-ZvJ7MXFIxjJ';
const JWT_ACCESS_SECRET = '6LcpcqkeAAAAALSRihgOQKR-z0QU-ZvJ7MXFIxjJ';
const JWT_ACCESS_TIMEOUT = '10s'; // 10 sec

export default new Vuex.Store({
   getters: {
      getAppTitle() {
         return APP_TITLE;
      },
      getCaptcha() {
         return RECAPTCHA_SECRET_FRONT;
      },
      getJWTAccessSecret() {
         return JWT_ACCESS_SECRET;
      },
      getJWTAccessTimeout() {
         return JWT_ACCESS_TIMEOUT;
      }
   },
   modules: {
   },
   strict: debug,
   plugins: debug ? [createLogger()] : [],
})