<template>
    <div class="site-main-wrapper" role="main">
        <div class="error404">
            <div class="page-background"></div>
            <section id="section1">
                <div class="container masthead-container">
                    <div class="row login-header-row">
                        <div class="col-xs-12 col-md-12 page-title-block text-center">
                            <h2 class="blue section-header-text">Sorry about that - we couldn't find the page you're
                                looking for.</h2>
                            <p>You may have clicked a link to a page that no longer exists, or it's possible there was a
                                typo in the URL.</p>
                        </div>
                        <div class="col-xs-12 col-md-12 text-center">
                            <div class="sign-up-now text-center"> <a class="btn backHome" href="/"> <span
                                          class="icon-thin-arrow-left"> </span>Go to the homepage</a></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
    };
  },
  computed: {},
  metaInfo () {
    return {
      title: 'Page Not Found | ' + this.$store.getters["getAppTitle"]
    } 
  },
};
</script>

<style scoped>
.error404 {
  background-image: url("../assets/images/diagonal-lines-two.png");
  background-repeat: repeat;
  background-color: #e1e1e1;
}
.error404 #section1 {
  min-height: 500px;
}
.error404 .section-header-text {
  font-weight: bold;
  text-transform: none;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}
</style>
