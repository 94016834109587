<template>
  <div>
    <GYHeader />
    <router-view />
    <GYFooter />
  </div>
</template>

<script>
import GYHeader from "./components/GYHeader";
import GYFooter from "./components/GYFooter";

export default {
  name: "GYN",
  components: {
    GYHeader,
    GYFooter,
  }
};
</script>